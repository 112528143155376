import { useTranslation } from 'react-i18next';
import countries from 'Countries/countries';
import { useMemo } from 'react';

interface Country {
    value: string;
    text: string;
    telPrefix: string;
    formattedLabel?: string;
    countryCode?: string;
}

function useLocalizedCountries(): Country[] {
    const { t } = useTranslation('back_royal');

    return useMemo(
        () =>
            countries
                .map(country => ({
                    value: country.value,
                    text: country.value ? t(`countries.country.${country.value}`) : '',
                    telPrefix: country.telPrefix,
                }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [t],
    );
}

export default useLocalizedCountries;
